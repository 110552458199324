/*
 * @Description:
 * @Version: 2.0
 * @Autor: libo
 * @Date: 2021-04-14 18:58:57
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:41:23
 */
const getters = {
  previewImages: state => state.park.previewImages,
  zoom: state => state.park.zoom,

  userInfo: state => state.user.userInfo,
  // baseInfo: state => state.park.baseInfo,
  // environmentInfo: state => state.park.environmentInfo,
  // alarmInfo: state => state.park.alarmInfo,
  // energyInfo: state => state.park.energyInfo,
  // personInfo: state => state.park.personInfo,
  parkingInfo: state => state.park.parkingInfo, // 停车场 卡片 （特例
  // deviceInfo: state => state.park.deviceInfo,
  // alarmList: state => state.park.alarmList,
  // abnormalList: state => state.park.abnormalList,

  parkInfo: state => state.park.parkInfo,
  parkBuildingInfo: state => state.park.parkBuildingInfo,
  parkBuildingFloorInfo: state => state.park.parkBuildingFloorInfo,
  parkEnvironDetail: state => state.park.parkEnvironDetail,
  parkPeoplesDetail: state => state.park.parkPeoplesDetail,
  parkAlarmDetail: state => state.park.parkAlarmDetail,
  parkDeviceDetail: state => state.park.parkDeviceDetail,
  parkEnergyDetail: state => state.park.parkEnergyDetail,
  parkParkingDetail: state => state.park.parkParkingDetail,
  parkTreeData: state => state.park.parkTreeData,

  personnelList: state => state.person.personnelList,
  personnelBasic: state => state.person.personnelBasic,
  staffDistribution: state => state.person.staffDistribution,
  personnelParam: state => state.person.personnelParam,
  personnelInfo: state => state.person.personnelInfo,
  vipThroughRecord: state => state.person.vipThroughRecord,
  insideThroughRecord: state => state.person.insideThroughRecord,
  sansThroughRecord: state => state.person.sansThroughRecord,

  energyDeviceCount: state => state.energy.energyDeviceCount,
  energyAlarmList: state => state.energy.energyAlarmList,
  energyDeviceList: state => state.energy.energyDeviceList,
  energyDeviceDistributionList: state =>
    state.energy.energyDeviceDistributionList,

  parkEnergyDeviceCount: state => state.energy.parkEnergyDeviceCount,
  parkEnergyAlarmList: state => state.energy.parkEnergyAlarmList,
  eneryDeviceList: state => state.energy.eneryDeviceList,
  eneryDeviceDistributionList: state =>
    state.energy.eneryDeviceDistributionList,

  groupTransitRecord: state => state.car.groupTransitRecord,
  monthlyCardTransitRecord: state => state.car.monthlyCardTransitRecord,
  tempTransitRecord: state => state.car.tempTransitRecord,

  environmentDeviceCount: state => state.environment.parkenvironmentDeviceCount,
  environmentGroupCount: state => state.environment.parkenvironmentGroupCount,
  environmentChange: state => state.environment.parkenvironmentChange,
  environmentAlarm: state => state.environment.parkenvironmentAlarm,
  environmentFacilityList: state =>
    state.environment.parkenvironmentFacilityList,
  environmentDetectionInfo: state =>
    state.environment.parkenvironmentDetectionInfo,

  securityBehavior: state => state.security.securityBehavior,
  securityAbnormalDistribution: state => state.security.securityAbnormalDistribution,
  securityAbnormalList: state => state.security.securityAbnormalList,
  securityDeviceCount: state => state.security.securityDeviceCount,
  securityDeviceList: state => state.security.securityDeviceList,
  securityDeviceDistribution: state => state.security.securityDeviceDistribution,
  securityAlarmList: state => state.security.securityAlarmList,

  fireDeviceCount: state => state.fire.fireDeviceCount,
  fireGroupCount: state => state.fire.fireGroupCount,
  firDeviceList: state => state.fire.firDeviceList,
  fireDeviceDistribution: state => state.fire.fireDeviceDistribution,
  fireAlarmList: state => state.fire.fireAlarmList,
  fireStatInfo: state => state.fire.fireStatInfo,
  fireList: state => state.fire.fireList,

  /**
   * @desc basicThree
   */
  basicThree: state => state.BasicThree,
  modelLevel: state => state.BasicThree.modelLevel,
  buildingName: state => state.BasicThree.buildingName,
  floorName: state => state.BasicThree.floorName,
  parkingName_3d: state => state.BasicThree.parkingName_3d,
  swiperIndex: state => state.BasicThree.swiperIndex
}
export default getters
