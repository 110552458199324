/*
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-07-12 14:07:59
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:42:30
 */

export default [
  {
    path: '/login',
    name: '登录',
    component: () => import(/* webpackChunkName: "page" */ '@/pages/login'),
    meta: {
      noAuth: true // 是否需要鉴权
    }
  }
]
