/*
 * @Description: 3d模型数据
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-08-13 17:58:50
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:41:37
 */
import {
  MUTATIONS_PARK3D__ENERGY_DEVICE_COUNT,
  MUTATIONS_PARK3D__ENERGY_ALARMLIST,
  MUTATIONS_PARK3D__ENERGY_DEVICEDISTRIBUTIONLIST,
  MUTATIONS_PARK3D__ENERGY_DEVICELIST
} from '../mutations_type'
const energyData = {
  state: () => {
    return {
      // 能耗设备数
      energyDeviceCount: {
        total: 0,
        totalOnline: 0,
        totalOffline: 0,
        totalFault: 0,
        percentageOnline: '0%'
      },
      // 能耗告警列表
      energyAlarmList: [],
      // 能耗设备列表
      energyDeviceList: [],
      // 能耗设备分布
      energyDeviceDistributionList: []
    }
  },
  mutations: {
    [MUTATIONS_PARK3D__ENERGY_DEVICE_COUNT](state, val) {
      console.log('能耗设备数', val)
      state.energyDeviceCount = val
    },
    [MUTATIONS_PARK3D__ENERGY_ALARMLIST](state, val) {
      console.log('能耗告警列表', val)
      state.energyAlarmList = val
    },
    [MUTATIONS_PARK3D__ENERGY_DEVICELIST](state, val) {
      console.log('能耗设备列表', val)
      state.energyDeviceList = val
    },
    [MUTATIONS_PARK3D__ENERGY_DEVICEDISTRIBUTIONLIST](state, val) {
      console.log('能耗设备分布', val)
      state.energyDeviceDistributionList = val
    }
  },
  actions: {
    // 首页能耗详情
    fn_api_get_park_energyDeviceCount({
      commit
    }, data) {
      commit(MUTATIONS_PARK3D__ENERGY_DEVICE_COUNT, data)
    },
    // 能耗告警列表
    fn_api_get_park_energyAlarmList({
      commit
    }, data) {
      commit(MUTATIONS_PARK3D__ENERGY_ALARMLIST, data)
    },
    // 能耗设备列表
    fn_api_get_park_energyDeviceList({
      commit
    }, data) {
      commit(MUTATIONS_PARK3D__ENERGY_DEVICELIST, data)
    },
    // 能耗设备分布
    fn_api_get_park_energyDeviceDistributionList({
      commit
    }, data) {
      commit(MUTATIONS_PARK3D__ENERGY_DEVICEDISTRIBUTIONLIST, data)
    }
  }
}
export default energyData
