/*
 * @Description:
 * @Version: 2.0
 * @Autor: libo
 * @Date: 2021-04-14 18:58:57
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:42:22
 */
import Router from './index'
import website from '../conf/website'
import store from '../store'
import { fn_validate__null } from '@/util/vaildate'
import Token from '../store/util/token'
import Storage from '../store/util/storage'
const fn_formatter__to = to => {
  const { path, name, meta } = to
  return { path, name, meta }
}
Router.beforeEach((to, from, next) => {
  console.log('to: ', to);
  return next()
  const userInfo = store.getters.userInfo
  const hasToken = Token.fn_token__get()
  const hasParkId = Token.fn_parkKey__get()
  // 临时打开新浏览器的标签
  if (/^\/?https?:.+/.test(to.path)) {
    window.open(to.path.slice(1))
    return next(false)
  }
  // if ((to.meta.noAuth && hasToken) || (!to.name && !hasToken)) {
  //   return next('/');
  // }
  if (!hasToken && fn_validate__null(userInfo) && !to.meta.noAuth) {
    debugger
    return next('/login')
  }

  if (!hasParkId && !fn_validate__null(userInfo) && to.meta.screen) {
    return next('/home')
  }

  if (fn_validate__null(userInfo) && hasToken) {
    store.dispatch('fn_a_user__handle_refresh_token')
    Promise.all([
      store.dispatch('fn_a_user__get_info')
      // store.dispatch(
      //   'fn_a_user__get_routers',
      //   Storage.fn_storage__get('active_menu')
      // ),
      // store.dispatch('fn_a_user__get_buttons')
    ])
      .then(() => {
        next({ ...to, replace: true })
      })
      .catch(() => {
        store.dispatch('fn_a_user__handle_logout')
      })
  } else {
    // store.dispatch('fn_a_tag__add', fn_formatter__to(to));
    if (to.fullPath === '/login' && hasToken) {
      return next('/home')
    }
    next()
  }
})
Router.afterEach(to => {
  document.title = to.meta.title || website.title // 动态设置浏览器标题
})
