/*
 * @Description:
 * @Version: 2.0
 * @Autor: libo
 * @Date: 2021-04-14 18:58:57
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:41:18
 */
import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import tags from './modules/tags'
import park from './modules/park'
import person from './modules/person'
import security from './modules/security'
import fire from './modules/fire'
import energy from './modules/energy'
import environment from './modules/environment'
import getters from './getters'
import Storage from './util/storage'
import car from './modules/car'
import BasicThree from '@store/modules/basicThree'
const { fn_storage__get } = Storage
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: fn_storage__get('language') || 'zh'
  },
  mutations: {},
  actions: {},
  modules: {
    user,
    tags,
    park,
    energy,
    person,
    environment,
    car,
    BasicThree,
    security,
    fire
  },
  getters
})
