/*
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-09-10 13:55:06
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:41:32
 */
/**
 * @auth 等猫的耗子
 * @version 1.0
 * 时间：2021-09-09
 */
import {
  MUTATIONS_PARK3D__BASICTHREE_HAVELOADED,
  MUTATIONS_PARK3D__BASICTHREE_BUILDING_STATUS,
  MUTATIONS_PARK3D__BASICTHREE_MODEL_LEVEL,
  MUTATIONS_PARK3D__BASICTHREE_BUILDING_NAME,
  MUTATIONS_PARK3D__BASICTHREE_FLOORNAME_NAME,
  MUTATIONS_PARK3D__BASICTHREE_PARIKING_NAME,
  MUTATIONS_PARK3D__BASICTHREE_SWIPER_INDEX
} from '../mutations_type'
const BasicThree = {
  state: () => {
    return {
      haveLoaded: false,
      buildingStatus: false,
      modelLevel: {
        level: 1
      },
      buildingName: '',
      floorName: '',
      parkingName_3d: -1,
      swiperIndex: '0'
    }
  },
  mutations: {
    [MUTATIONS_PARK3D__BASICTHREE_HAVELOADED](state, val) {
      state.haveLoaded = val
    },
    [MUTATIONS_PARK3D__BASICTHREE_BUILDING_STATUS](state, val) {
      state.buildingStatus = val
    },
    [MUTATIONS_PARK3D__BASICTHREE_MODEL_LEVEL](state, {
      level
    }) {
      state.modelLevel.level = level
    },
    [MUTATIONS_PARK3D__BASICTHREE_BUILDING_NAME](state, val) {
      state.buildingName = val
    },
    [MUTATIONS_PARK3D__BASICTHREE_PARIKING_NAME](state, val) {
      state.parkingName_3d = val
    },
    [MUTATIONS_PARK3D__BASICTHREE_FLOORNAME_NAME](state, val) {
      state.floorName = val
    },
    [MUTATIONS_PARK3D__BASICTHREE_SWIPER_INDEX](state, val) {
      state.swiperIndex = val
    }
  },
  actions: {
    setFloorName({
      commit
    }, data) {
      commit(MUTATIONS_PARK3D__BASICTHREE_FLOORNAME_NAME, data)
    },
    setBuildingName({
      commit
    }, data) {
      commit(MUTATIONS_PARK3D__BASICTHREE_BUILDING_NAME, data)
    },
    setParkingName({
      commit
    }, data) {
      commit(MUTATIONS_PARK3D__BASICTHREE_PARIKING_NAME, data)
    },
    setHaveLoaded({
      commit
    }, data) {
      commit(MUTATIONS_PARK3D__BASICTHREE_HAVELOADED, data)
    },
    setBuildingStatus({
      commit
    }, data) {
      commit(MUTATIONS_PARK3D__BASICTHREE_BUILDING_STATUS, data)
    },
    setModelLevel({
      commit
    }, data) {
      commit(MUTATIONS_PARK3D__BASICTHREE_MODEL_LEVEL, data)
    },
    setSwiperIndex({
      commit
    }, data) {
      commit(MUTATIONS_PARK3D__BASICTHREE_SWIPER_INDEX, data)
    }
  }
}

export default BasicThree
